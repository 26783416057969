<template>
  <article id="wrap">

    <form novalidate="true">

      <header>
        <ul class="page_head">
          <li class="menuL" v-if="!isConfirm"><router-link :to="{name:'mypage'}"><img src="@/assets/img/icon_back.png"></router-link></li>
          <li class="menuL" v-if="isConfirm"><a @click="isConfirm = false;"><img src="@/assets/img/icon_back.png"></a></li>
          <li class="menuC"><h1 class="page_head_h1">わたしのこと</h1></li>
          <li class="menuR"></li>
        </ul>
      </header>


      <section v-if="!isConfirm" class="contents">

        <div class="contentInner">
          <div class="white_bg_area">
            <div>
              <div>商品情報入力</div>
              <div>販売したい商品情報を入力してください。</div>
            </div>

            <div class="form-group form-group--nick" :class="{ 'form-group--error': $v.name.$error }">
              <label>商品名</label>
<!--              <div class="error" v-if="$v.nick.$dirty && !$v.nick.required">入力してください</div>-->
<!--              <div class="error" v-if="!$v.nick.maxLength">{{ $v.nick.$params.maxLength.max }}文字以内で入力してください</div>-->
              <input type="text" :placeholder="`${$v.name.$params.maxLength.max}文字以内で入力してください`"
                     :value="name"
                     @change="setValue('name', $event.target.value)"/>
            </div>

<!--            <div :class="{ 'form-group&#45;&#45;error': $v.last_name.$error || $v.first_name.$error }">-->
<!--              <label>お名前</label>-->
<!--              <div class="error" v-if="($v.last_name.$dirty && $v.first_name.$dirty) && (!$v.last_name.required || !$v.first_name.required)">入力してください</div>-->
<!--              <div class="form_2col">-->
<!--                <input type="text" :value="last_name" placeholder="姓" @change="setValue('last_name', $event.target.value)">-->
<!--                <input type="text" :value="first_name" placeholder="名" @change="setValue('first_name', $event.target.value)">-->
<!--              </div>-->
<!--            </div>-->

<!--            <div :class="{ 'form-group&#45;&#45;error': $v.last_kana.$error || $v.first_kana.$error }">-->
<!--              <label>フリガナ</label>-->
<!--              <div class="error" v-if="($v.last_kana.$dirty && $v.first_kana.$dirty) && (!$v.last_kana.required || !$v.first_kana.required)">入力してください</div>-->
<!--              <div class="form_2col">-->
<!--                <input type="text" :value="last_kana" placeholder="セイ" @change="setValue('last_kana', $event.target.value)">-->
<!--                <input type="text" :value="first_kana" placeholder="メイ" @change="setValue('first_kana', $event.target.value)">-->
<!--              </div>-->
<!--            </div>-->

<!--            <div :class="{ 'form-group&#45;&#45;error': $v.birthday.$error }">-->
<!--              <label>生年月日</label>-->
<!--              <div class="error" v-if="$v.birthday.$dirty && !$v.birthday.required">入力してください</div>-->
<!--              <div class="select-ymd">-->
<!--                <div class="select-wrap">-->
<!--                  <select name="year" v-model="birthday_year">-->
<!--                    <option disabled value="">選択</option>-->
<!--                    <option v-for="year in birth_years"-->
<!--                            :value="year"-->
<!--                            :key="`year_${year}`"-->
<!--                            @input="setValue('birthday', birthday)"-->
<!--                    >{{ year }}年</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--                <div class="select-wrap">-->
<!--                  <select name="month" v-model="birthday_month">-->
<!--                    <option disabled value="">選択</option>-->
<!--                    <option v-for="month in Array.from(Array(12).keys()).map(i => ('0' + ++i).slice(-2))"-->
<!--                            :value="month"-->
<!--                            :key="`month_${month}`"-->
<!--                            @input="setValue('birthday', birthday)"-->
<!--                    >{{ month.replace(/^0/, '') }}月</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--                <div class="select-wrap">-->
<!--                  <select name="date" v-model="birthday_day">-->
<!--                    <option disabled value="">選択</option>-->
<!--                    <option v-for="day in Array.from(Array(31).keys()).map(i => ('0' + ++i).slice(-2))"-->
<!--                            :value="day"-->
<!--                            :key="`day_${day}`"-->
<!--                            @input="setValue('birthday', birthday)"-->
<!--                    >{{ day.replace(/^0/, '') }}日</option>-->
<!--                  </select>-->

<!--                  &lt;!&ndash;        <input type="date" :value="birthday" placeholder="生年月日を選択してください"&ndash;&gt;-->
<!--                  &lt;!&ndash;               @input="$emit('update:birthday', $event.target.value)">&ndash;&gt;-->
<!--                </div>&lt;!&ndash; .select-wrap &ndash;&gt;-->
<!--              </div>-->
<!--            </div>-->

<!--            <template v-if="mode==='create'">-->
<!--              <div :class="{ 'form-group&#45;&#45;error': $v.email.$error }">-->
<!--                <label>メールアドレス</label>-->
<!--                <div class="error" v-if="$v.email.$dirty && !$v.email.required">入力してください</div>-->
<!--                <div class="error" v-if="$v.email.$dirty && !$v.email.maxLength">200文字以内で入力してください</div>-->
<!--                <div class="error" v-if="$v.email.$dirty && !$v.email.email">正しいメールアドレスを入力してください</div>-->
<!--                <div class="error" v-if="$v.email.$dirty && !$v.email.unique">すでに使用されています</div>-->
<!--                <input type="email" :value="email" placeholder="メールアドレスを入力してください"-->
<!--                       @change="setValue('email', $event.target.value)">-->
<!--              </div>-->

<!--              <div :class="{ 'form-group&#45;&#45;error': $v.password.$error }">-->
<!--                <label>パスワード</label>-->
<!--                <div class="error" v-if="$v.password.$dirty && !$v.password.required">入力してください</div>-->
<!--                <div class="error" v-if="$v.password.$dirty && !$v.password.minLength">4文字以上で入力してください</div>-->
<!--                <div class="error" v-if="$v.password.$dirty && !$v.password.maxLength">200文字以内で入力してください</div>-->
<!--                <input type="password" :value="password" placeholder="パスワードを入力してください"-->
<!--                       @change="setValue('password', $event.target.value)">-->
<!--              </div>-->

<!--              <div :class="{ 'form-group&#45;&#45;error': $v.password_confirm.$error }">-->
<!--                <label>パスワード確認</label>-->
<!--                <div class="error" v-if="$v.password_confirm.$dirty && !$v.password_confirm.sameAsPassword">パスワードと同じ文字列を入力してください</div>-->
<!--                <input type="password" :value="password_confirm" placeholder="パスワードを入力してください"-->
<!--                       @change="setValue('password_confirm', $event.target.value)">-->
<!--              </div>-->
<!--            </template>-->

<!--            <div>-->
<!--              <label>ひと言</label>-->
<!--              <textarea :value="comment" rows="5" cols="" placeholder="メッセージを入力してください"-->
<!--                        @change="setValue('comment', $event.target.value)"></textarea>-->
<!--            </div>-->

          </div><!-- .white_bg_area -->

          <div class="bt_form"><button type="button" value="send" @click="next">確認</button></div>

        </div><!--.contentInner-->

      </section><!--.contents-->

      <AppFooter fixed></AppFooter>

    </form>

  </article>
</template>

<script>
import AppFooter from '@/components/AppFooter';
import {validationMixin} from 'vuelidate';
import {maxLength, required} from 'vuelidate/lib/validators';
export default {
  components: { AppFooter },
  data () {
    return {
      form: {
        name: '',
      },
      error: 0,
      isConfirm: false,
    }
  },
  computed: {
    image () {
      if (!this.artist || !this.artist.image) return false;
      return `${process.env.VUE_APP_API_ROOT}../${this.artist.image}`;
    },
    background () {
      return `url(${this.image}) no-repeat top center`
    },
    inputCssVars () {
      return {
        '--before-background': this.background
      }
    }
  },
  mixins: [validationMixin],
  validations() {
    return {
      name: {
        required,
        maxLength: maxLength(10),
      },
    }
  },
  created () {
    this.initialize();
  },
  methods: {
    async initialize () {
      // const response = await this.$http.get(`artists/${this.$route.params.artist}`);
      // this.artist = response.data;
    },
    next () {
      // validation
      this.error = this.$refs.profile.invalid();

      if (!this.error) {
        this.isConfirm = true;
        // this.$router.push({name:'signup-confirm'});
      } else {
        document.scrollingElement.scrollTop = 0;
      }
    },
  }
}
</script>
